import {
  FormSubmissionComment, FormSubmissionConfig,
  FormattedFormSubmittionComment, GetFormSubmissionsCommentsMeta
} from '@modules/forms/types';
import ShowMoreComments
  from '@modules/social/components/feed-item/message/show-more-comments';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as userSelector from '@modules/core/selectors/logged-user';
import { useAppSelector } from '@common/hooks';
import { AsyncList } from '@common/components/list';
import { ApiResponse } from '@common/services/api/types';
import { UnboundCommentComponent } from '@modules/social/components/comment';

type SubmissionCommentsListProps = {
  comments: FormattedFormSubmittionComment[] | null;
  submissionConfig: FormSubmissionConfig;
  onDelete: (commentId: string) => Promise<void>;
  getComments: () => Promise<
    ApiResponse<FormSubmissionComment[], GetFormSubmissionsCommentsMeta>
  >;
};

const SubmissionCommentsList = memo(({
  comments,
  submissionConfig,
  onDelete,
  getComments
}: SubmissionCommentsListProps) => {
  const { t } = useTranslation();
  const loggedUser = useAppSelector(userSelector.selected);
  return (
    <AsyncList
      order="DESC"
      items={comments || []}
      data={{
        // @ts-expect-error
        onFetch: getComments,
        filter: {
          id: submissionConfig.id,
        },
      }}
      // @ts-expect-error
      renderRow={UnboundCommentComponent}
      rowProps={{
        loggedUser,
        onDelete,
        message: submissionConfig,
      }}
      placeholder={t('common:no_comments_yet')}
      ShowMoreComponent={ShowMoreComments}
    />
  );

});

export default SubmissionCommentsList;
